<template>
  <div>
    <!-- 查看订单箱入库信息 -->
    <el-dialog
    :title="$t('chinaWhB2BOrderPage.dialogPackageInfoTitle')"
    :close-on-click-modal="false"
    :visible.sync="popup"
    width="1200px"
    :before-close="handleDialogClose"
    >
      <el-row>
      	<label
      		style="padding: 20px;display: inline-block;font-size: 18px;">{{$t('commonInfo.orderNoColumn') + ':'}}</label><span
      		style="padding: 20px;display: inline-block; font-size: 18px;">{{rowId}}</span>
      </el-row>
			<el-row class="cartonCount">
			  <label>{{$t('chinaWhB2BOrderPage.inWhCount')}}: <span style="color: #01AAED;">{{cartonCountInfo.inWhCount}}</span></label>
			  <label>{{$t('chinaWhB2BOrderPage.cancelCount')}}: <span style="color: #dc191d;">{{cartonCountInfo.cancelCount}}</span></label>
			</el-row>
      <el-table :data="packageData" v-loading="packageListLoading" show-summary :summary-method="getSummaries">
      	<el-table-column prop="clientLgPackageNo" :label="$t('commonInfo.packageNo')" width="180"
      		align="center">
      	</el-table-column>
      	<el-table-column prop="esnadLgPackageNo" :label="$t('commonInfo.dlvdPackageNo')" width="180"
      		align="center">
      	</el-table-column>
      	<el-table-column prop="subOrderNo" :label="$t('commonInfo.batchNo')" align="center" width="140">
      	</el-table-column>
      	<el-table-column prop="length" :label="$t('commonInfo.length') + '(CM)'" width="80" align="center">
      	</el-table-column>
      	<el-table-column prop="breadth" :label="$t('commonInfo.breadth') + '(CM)'" width="80" align="center">
      	</el-table-column>
      	<el-table-column prop="height" :label="$t('commonInfo.height') + '(CM)'" width="80" align="center">
      	</el-table-column>
      	<el-table-column prop="grossWeight" :label="$t('commonInfo.grossWeight') + '(KG)'" width="80" align="center">
      	</el-table-column>
      	<el-table-column prop="retreatWeight" :label="$t('commonInfo.retreatWeight') + '(KG)'" width="95" align="center">
      	</el-table-column>
      	<el-table-column prop="chargedWeight" :label="$t('commonInfo.chargeWeight') + '(KG)'" width="95" align="center">
      	</el-table-column>
      	<el-table-column prop="productAmount" :label="$t('commonInfo.productAmount')" width="110"  align="center">
      	</el-table-column>
      	<el-table-column prop="picUrl" min-width="100" :label="$t('commonInfo.photo')" align="center">
      		<template slot-scope="{row}">
      			<div v-if="row.picUrl != null && row.picUrl != ''">
      				<el-button type="text" @click="handleViewCartonImg(row.picUrl)" icon="el-icon-view">
      					{{$t('rtoFbxOrderPage.checkPhoto')}}</el-button>
      			</div>
      			<span v-else></span>
      		</template>
      	</el-table-column>
      </el-table>
    </el-dialog>
  </div>

</template>

<script>
  export default {
    name: 'CheckCartonInfo',
    // 获取父组件信息
    props: {
      isShow:{
      	type:Boolean,
      	default:function(){
      		return false;
      	}
      },
      rowId:{
        type:String,
        default:function(){
        	return '';
        }
      },
    },
    watch:{
      //监听按钮名称和提示文字
      'isShow':{
      	handler(newValue){
          if(newValue){
            this.popup = newValue;
            this.getPackageList(this.rowId);
          }
      	},
      	immediate: true,
      },
    },
    data() {
      return {
        popup:false,
        packageListLoading:false,
        packageData:[],
				cartonCountInfo:{
				  inWhCount:0,
				  cancelCount:0,
				},
        
        total:0,
      }
    },

    //方法集合
    methods: {
      // 关闭窗口
      handleDialogClose(){
        this.popup = false;
        this.packageData = [];
        this.$parent.packageDialogPopup = false;
        // 清除数据
        this.$parent.rowId = '';
      },
			// 获取重量信息合计
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = this.$t('commonInfo.secondTotal');
						return;
					}
					if (column.label == this.$t('commonInfo.grossWeight') + '(KG)') {
						let gwSum = 0;
						data.map(item => {
							let gwValue = item.grossWeight == null ? 0 : item.grossWeight;
							gwSum += Number((gwValue).toFixed(2));
						});
						sums[index] = gwSum == 0 ? '' : gwSum.toFixed(2);
					} else if (column.label == this.$t('commonInfo.chargeWeight') + '(KG)') {
						let cwSum = 0;
						data.map(item => {
							let cwValue = item.chargedWeight == null ? 0 : item.chargedWeight;
							cwSum += Number((cwValue).toFixed(2));
						});
						sums[index] = cwSum == 0 ? '' : cwSum.toFixed(2);
					} else {
						sums[index] = '';
					}
				});
				return sums;
			},
      // 获取箱信息列表
      async getPackageList(id) {
      	this.packageListLoading = true;
      	const res = await this.$http.get(
      		"/toborder/clientlgpackage/info/" + id
      	);
      	if (res.code == 200) {
      		this.packageData = res.data.detailList;
					this.cartonCountInfo.inWhCount = res.data.inWhCount;
					this.cartonCountInfo.cancelCount = res.data.cancelCount;
      		this.packageListLoading = false;
      	} else {
      		this.$message.error(res.msg);
      		this.packageListLoading = false;
      	}
      },
			// 查看箱图片
			handleViewCartonImg(url) {
				this.$parent.imgList = [];
				this.$parent.imgList.push(url);
				this.$parent.showImg = true;
			},
    },
    created() {
    },

  }
</script>

<style scoped>
	.cartonCount{
		padding-left: 20px;
	  font-size: 16px;
	  margin-bottom: 10px;
	}
	.cartonCount label{
	  margin-right: 40px;
	}
</style>
